import React from 'react';
import styled from 'styled-components';

const MapContainer = styled.img`
    grid-area: map;
    height: 300px;
    width: 300px;
    margin-left: 40px;
`;

let map;

export default class AppMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    render() {

        function getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min);
          }

          
          const COORDS = [
              "-119.7172,34.4312,11.24,243,60",
              "-73.9731,40.726,10.74,29,60",
              "-122.6518,45.5213,12.28,66,60",
              "-121.3001,37.9621,12.51,0,60",
              "-122.3627,37.8054,9.63,0,60",
              "-77.0245,38.8919,11.55,0,60",
              "-76.5195,3.4109,11.35,60,60",
              "-97.9977,35.1021,7.38,0,47",
              "-118.3107,34.1126,10.11,309,60",
              "1.4748,43.7683,8.8,0,60",
              "-3.7094,40.4157,12.61,32,60",
              "30.3026,59.9332,11.71,32,60",
              "60.607,56.8374,12.81,0,60",
            ]
        const PLACE_LIVED = COORDS[getRandomIntInclusive(0,COORDS.length - 1)]
        
        const MAP_ROOT = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${PLACE_LIVED}/300x300?access_token=pk.eyJ1Ijoibmlja2ZyZWVtYW54eXoiLCJhIjoiY2wwcHNhaWVmMjJ6aTNjbjVucDAzeTYxeCJ9.BsMG0DdKZ3EInOPBooDX_A`
            
        return (

            <MapContainer
                className="maps"
                src={MAP_ROOT}/>
                
        )

    }
}







