import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


// import components
import Leftbar from './Leftbar'
import Rightbar from './Rightbar'
import MainHomeView from './MainHomeView'

import coffee from '!!file-loader!../assets/bacon.png'

const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: 7fr 24fr 4fr;
  font-family: "Arial", sans-serif;
  margin: auto;
  padding: 15px;
`;

const HomeContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const wait = (time) => {
  return new Promise( res => {
    setTimeout( () => res(), time)
  })
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; 
}

const animateBacon = async (bacon) => {
  let targetBacon = document.getElementById(bacon.key)
    let top = 10
    for (let i = 0; i < 100 ; i++) {
      await wait(10)
      targetBacon.style.top = `${top}px`
      top = top + 10
    }
  targetBacon.remove()

}

const Home = (props) => { 
  
  const [baconDivs, manageBaconDivs] = useState([])
  
  const handleBaconTrigger = async (val) => {
    
    const rand = getRandomIntInclusive
  
    const Bacon = styled.img`
      position: absolute;
      transform: rotate(90deg);
      left: ${rand(0, (window.screen.width * .7))}px;
    `
    const randNum = rand(0, 100)
    const randChar = alphabet[rand(0, 25)]
    const baconId = `bacon-${ randNum + randChar }`
    const randBacons = [...baconDivs].concat([<Bacon id={baconId} key={baconId} src={coffee}/>])

    manageBaconDivs(randBacons)
    await wait(1000)
  }
  
  useEffect(() => {
    if (baconDivs.length > 0) {
      const lastBacon = baconDivs.length - 1
      animateBacon(baconDivs[lastBacon])
    }
  })

    return (
      <HomeContainer>
        <Leftbar triggerBacon={handleBaconTrigger}/>
        <HomeContent>
          <MainHomeView
            setApp={props.setApp}
            data={props.data}
            toggleExpVisibility={props.toggleExpVisibility}
            triggerBacon={handleBaconTrigger}/>
        </HomeContent> 
        <Rightbar triggerBacon={handleBaconTrigger}/>
        {baconDivs.map(bacon => bacon)}
      </HomeContainer>
    )
}

module.exports = Home

